<template>
  <div class="bg-silver px-2">
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>

    <v-divider class="--border-white"></v-divider>

    <!-- =============================board booking=================== -->
    <v-row class="mx-auto py-8">
      <v-col v-if="nearFutureBookings.length > 0" cols="12" class="pb-0">
        <h2 class="text-bold --prm-light font-weight-bold">
          {{ $t("title.mostRecentBooking") }}
        </h2>
      </v-col>
      <v-col
        v-for="booking in nearFutureBookings"
        :key="booking.id"
        cols="12"
        sm="6"
      >
        <UsageHistoryItem
          :id="booking.id"
          :urlImg="booking.room.roomType.facility.mainImageUrl"
          :title="booking.room.roomType.facility.name"
          :checkInDate="booking.checkInDate"
          :checkOutDate="booking.checkOutDate"
          :roomTypeName="booking.room.roomType.name"
          :representativeName="booking.representative ? booking.representative.name : null"
          :cancelledAt="booking.cancelledAt"
          class="elevation-1"
        />
      </v-col>
    </v-row>

    <v-row class="mx-auto pb-8">
      <v-col v-if="futureBookings.length > 0" cols="12" class="pb-0">
        <h2 class="text-bold --prm-light font-weight-bold">
          {{ $t("reservationAndUsageHistory.currentRoomReservation") }}
        </h2>
      </v-col>
      <v-col
        v-for="booking in futureBookings"
        :key="booking.id"
        cols="12"
        sm="6"
      >
        <UsageHistoryItem
          :id="booking.id"
          :urlImg="booking.room.roomType.facility.mainImageUrl"
          :title="booking.room.roomType.facility.name"
          :checkInDate="booking.checkInDate"
          :checkOutDate="booking.checkOutDate"
          :roomTypeName="booking.room.roomType.name"
          :representativeName="booking.representative ? booking.representative.name : null"
          :cancelledAt="booking.cancelledAt"
          class="elevation-1"
        />
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col cols="12">
        <h2
          v-if="pastBookings.length > 0"
          class="text-bold --prm-light font-weight-bold pt-6 pb-3 px-4"
        >
          {{ $t("reservationAndUsageHistory.pastAccommodationReservations") }}
        </h2>
      </v-col>
      <v-col
        v-for="booking in pastBookings"
        :key="booking.id"
        cols="12"
        sm="6"
      >
        <UsageHistoryItem
          :id="booking.id"
          :urlImg="booking.room.roomType.facility.mainImageUrl"
          :title="booking.room.roomType.facility.name"
          :checkInDate="booking.checkInDate"
          :checkOutDate="booking.checkOutDate"
          :roomTypeName="booking.room.roomType.name"
          :representativeName="booking.representative ? booking.representative.name : null"
          :cancelledAt="booking.cancelledAt"
          class="elevation-1"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="pastBookings.length > 0"
        class="text-center pb-9 mx-auto"
      >
        <v-btn
          elevation="0"
          @click="loadMorePastBookings"
          outlined
          rounded
          x-large
          class="bg-white"
          color="primary"
        >
          {{ $t("reservationAndUsageHistory.seeMore") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UsageHistoryItem from '../../components/UsageHistoryItem'
import { BookingTypeIds } from '@/constants/booking-type'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Booking',
  components: {
    Breadcrumbs,
    UsageHistoryItem
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadNearFutureBookings')
        await this.$store.dispatch('loadFutureBookings')
        await this.$store.dispatch('loadPastFutureBookings')
      })
    })
  },
  methods: {
    async loadMorePastBookings () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('loadPastFutureBookings')
        })
      })
    },
    isCancelled (booking) {
      return !!booking.cancelledAt
    }
  },
  computed: {
    nearFutureBookings () {
      return this.nearFutureVsBookings.filter(b => !this.isCancelled(b))
    },
    futureBookings () {
      return this.futureVsBookings.filter(b => !this.isCancelled(b))
    },
    pastBookings () {
      return this.nearFutureVsBookings.filter(b => this.isCancelled(b)).concat(
        this.futureVsBookings.filter(b => this.isCancelled(b))
      ).concat(
        this.pastVsBookings
      )
    },
    nearFutureVsBookings () {
      return this.$store.getters.nearFutureBookings
    },
    futureVsBookings () {
      return this.$store.getters.futureBookings
    },
    pastVsBookings () {
      return this.$store.getters.pastBookings
    }
  }
}
</script>
